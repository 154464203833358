import React from "react";
import Box from "@mui/material/Box";
import SliderCen from "../../pages/slider/SliderCen";
import Benchmark from "../../pages/Benchmark/Benchmark";
import riskIcon from "../../asset/risk_icon.png";
import rightA from "../../asset/icon/ArrowRight.png";
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Link } from "react-router-dom";
import { Icon } from '@material-ui/core';
export default function Dashboard({ update, rightOpen, leftOpen,switchShow }) {
  console.log(rightOpen, leftOpen);
  return (
    <div class="container" style={{ marginLeft: "-23px" }}>
      <div
        class={
          rightOpen === false && leftOpen === false
            ? "row row-cols-2 row-cols-lg-2 row-mr-162"
            : rightOpen === true && leftOpen === false
            ? "row row-cols-2 row-cols-lg-2 row-mr-141"
            : 
            rightOpen === false && leftOpen === true  ?
            "row row-cols-2 row-cols-lg-2 row-mr-85"
            :
            "row row-cols-2 row-cols-lg-2 row-mr-70"
        }
      >
        {/* {
          switchShow ? */}
          <>

<div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          
          <Box className="risk" >
            <Icon className="rishIcon"><DashboardOutlinedIcon/></Icon>
            <Box className="titleRisk">Safety Dashboard</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
        </div>

          <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          {" "}
          
            <Box className="risk">
              <Icon className="rishIcon"><LibraryBooksOutlinedIcon/></Icon>
              <Box className="titleRisk-data-collection">Collect Renewal Data</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
        </div>
          <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          {" "}
          
            <Box className="risk" >
              <Icon className="rishIcon"><DescriptionOutlinedIcon/></Icon>
              <Box className="titleRisk">Add Claim</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
        </div>
      
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
        
          <Box className="risk" >
            <Icon className="rishIcon"><FindInPageOutlinedIcon/></Icon>
            <Box className="titleRisk">Search Claim</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          
            <Box className="risk" >
              <Icon className="rishIcon"><AssessmentOutlinedIcon/></Icon>
              <Box className="titleRisk">Board Report</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
        </div>
        
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          
          <Box className="risk">
            <Icon className="rishIcon"><AccountBoxOutlinedIcon/></Icon>
            <Box className="titleRisk">User Management</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
        </div>

          </>
          {/* :
          <>

<div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-2"}>
          <Link
            to={"/analytics"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
          <Box className="risk" onClick={() => update(0)}>
            <Icon className="rishIcon"><DesktopWindowsOutlinedIcon/></Icon>
            <Box className="titleRisk">Safety Dashboard</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
          </Link>
        </div>

          <div class={rightOpen ? "col-2 col-lg-2" : "col-3 col-lg-2"}>
          {" "}
          <Link
            className="menuitem"
            to={"/digital-intake"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Box className="risk" onClick={() => update(0)}>
              <Icon className="rishIcon"><DesktopWindowsOutlinedIcon/></Icon>
              <Box className="titleRisk">Renewal Data Collection</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
          </Link>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          <Link
            to={"/risk"}
            className="menuitem"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Box className="risk" onClick={() => update(0)}>
              <Icon className="rishIcon"><DesktopWindowsOutlinedIcon/></Icon>
              <Box className="titleRisk">Add Claim</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
          </Link>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-2"}>
          <Link
            to={"/risk-analytics"}
            className="menuitem"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
          <Box className="risk" onClick={() => update(0)}>
            <Icon className="rishIcon"><DesktopWindowsOutlinedIcon/></Icon>
            <Box className="titleRisk">Search for a Claim</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
          </Link>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-2"}>
          <Link
            className="menuitem"
            to={"/claims"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Box className="risk" onClick={() => update(0)}>
              <Icon className="rishIcon"><DesktopWindowsOutlinedIcon/></Icon>
              <Box className="titleRisk">Board Report</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
          </Link>
        </div>
        
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-2"}>
          <Link
            to={"/claims-analytics"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
          <Box className="risk" onClick={() => update(0)}>
            <Icon className="rishIcon"><DesktopWindowsOutlinedIcon/></Icon>
            <Box className="titleRisk claims-analytics-margin">User Management</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
          </Link>
        </div>
       
        
          </>
        } */}
      </div>
      {rightOpen === true && leftOpen === false ? (
        <Box
          sx={{
            position: "absolute",
            width: "82%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : rightOpen === false && leftOpen === true ? (
        <Box
          sx={{
            position: "absolute",
            width: "87%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : rightOpen && leftOpen ? (
        <Box
          sx={{
            position: "absolute",
            width: "74%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : rightOpen === false && leftOpen === false ? (
        <Box
          sx={{
            position: "absolute",
            width: "97%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            width: "74%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      )}
    </div>
  );
}
