import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { Icon } from '@material-ui/core';
import bgSlide from "../../asset/icon/photo-1661956601031-4cf09efadfce.avif";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import { Box } from "@mui/material";

export default function SideSlider(props) {
  const navigate = useNavigate();
  return (
    <>
    {/*  <Swiper
        navigation={false}
        pagination={true}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
         <SwiperSlide>
          <Box className="slide-img4">
            <Box className="slider-tilte-news px-4">
              March 21, 2023 | March 23, 2023
              <br/>
              Westin Riverwalk San Antonio, Texas
              <br/>
              
            </Box>
            <span>Ventive Experience 2023 San Antonio</span>
          </Box>
        </SwiperSlide>
         <SwiperSlide>
          {" "}
          <Box className="slide-img2">
            <Box className="slider-tilte px-4">
              Ventiv Technology RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img3">
            <Box className="slider-tilte px-4">
              Ventiv Technology
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img1">
            <Box className="slider-tilte px-4">
              Ventiv Technology RMIS
            </Box>
          </Box>
        </SwiperSlide> 
      </Swiper> */}
 <Box className="slide-img4">
            <Box></Box>
          </Box>

      <Box className="box-cont-holder">
        <Box className="side-content-box">
          
          <Box className="sb-til">
         
          March 21, 2023 | March 23, 2023
            
          </Box>
          <Box className="sb-til">
          <Icon ><RoomOutlinedIcon/></Icon>
          Westin Riverwalk San Antonio, Texas
          </Box>
          <Box className="tle-12-bld-7">2023 RMIA Risk Conference</Box>
        </Box>
      </Box>
    </>
  );
}
