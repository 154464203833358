import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid } from "@mui/material";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
// import img1 from "../../asset/dash_vendiv.png";
import img1 from "../../asset/new screens/logo.png";

import Box from "@mui/material/Box";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import LanguageIcon from "@mui/icons-material/Language";
import Dashboard from "../../pages/Dashboard/Dashboard";
import NewesSection from "../NewsSection/NewsSection";
import arrow from "../../asset/arrow.png";
import Switch from "@material-ui/core/Switch";
import ClaimsAnalytics from "../../pages/ClaimsAnalytics/ClaimsAnalytics";
import RiskAnalytics from "../../pages/RiskAnalytics/RiskAnalytics";
import Tooltip from "@material-ui/core/Tooltip";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DigitalFrame from "../../pages/Digital/DigitalFrame";
import RiskFrame from "../../pages/Risk/RiskFrame";
import ClaimsFrame from "../../pages/Claims/ClaimsFrame";
import AnalyticsFrame from "../../pages/Policy/AnalyticsFrame"
import DoubleArrowOutlinedIcon from "@material-ui/icons/DoubleArrowOutlined";
import Analytics from "../../asset/decision-analytics-icon.png"
import BillingImg from "../../asset/billing-icon.png"
import DigitalIntake from "../../pages/Digital-Intake/DigitalIntake";

const drawerWidth = 110;
const color = localStorage.getItem("color");
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
  },
  popover: {
    pointerEvents: "none",
    width: 400,
    color: "red",
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth + 260}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#fff",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#00677F",
  },
  drawerRight: {
    flexShrink: 0,
    width: " 240px !important",
    backgroundColor: "none !important",
  },
  drawerPaperRight: {
    width: 240,
    backgroundColor: "#fff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "0px !important",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -150,
  },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  contentShift: {
    marginLeft: "-57px",
    backgroundColor: "#fff",
    padding: "4px 24px !important",
  },
  contentRight: {
    flexGrow: 1,
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -250,
  },
  contentRightMenu: {
    flexGrow: 1,
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -50,
  },
  contentShiftRight: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    marginTop: "20px",
    backgroundColor: "#fff",
  },
  title: {
    flexGrow: 1,
  },
}));

export default function DrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [rightOpen, setRightOpen] = React.useState(true);
  const [menuIndex, setMenuIndex] = React.useState(0);
  const [riskShow, setRiskShow] = React.useState(false);
  const [claimsShow, setClaims] = React.useState(false);
  const [isParentData, setIsParentData] = React.useState(false);
  const [propsIndex, setPropsIndex] = React.useState(props.indexMenu);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRisk, setAnchorElRisk] = React.useState(null);
  const [switchShow, setSwitchShow] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openRiskMenu, setOpenRiskMenu] = React.useState(false);
  let onClickFunction = (arg) => {
    setMenuIndex(arg);
    setPropsIndex("false");
    setIsParentData(false);
  };
  const handleDrawerOpen = useCallback(() => setOpen(!open), [open]);
  const handleRightDrawerOpen = useCallback(
    () => setRightOpen(!rightOpen),
    [rightOpen]
  );
  const handleDrawerClose = useCallback(() => setOpen(false));
  const handleRightDrawerClose = useCallback(() => setRightOpen(false));
  const menuClick = useCallback((idx) => {
    console.log(idx)
    if (idx === 0) {
      setIsParentData(true);
      setPropsIndex("false");
    } else if (idx === 1) {
      setPropsIndex("false");
      setIsParentData(false);
      handlePopoverClose();
    } else if (idx === 2) {
      setPropsIndex("false");
      setIsParentData(false);
      handlePopoverCloseRisk();
    } else if (idx === 3 || idx === 4) {
      setPropsIndex("false");
      setIsParentData(false);
      handlePopoverCloseRisk();
    } else if (idx === 6 || idx === 7 || idx === 8) {
      setPropsIndex("false");
      setIsParentData(false);
      handlePopoverClose();
    }
    setOpenMenu(false);
    setOpenRiskMenu(false);
    setMenuIndex(idx);
  });
  const showClaims = useCallback((event) => {
    setClaims(!claimsShow);
    setRiskShow(false);
    setAnchorEl(null);
  });
  const closeMenu = useCallback(() => {
    setOpenMenu(false);
    setOpenRiskMenu(false);
  });
  const handlePopoverOpen = useCallback((event) => {
    setOpenMenu(!openMenu);
    setOpenRiskMenu(false);
    setAnchorEl(event.currentTarget);
  });
  const handlePopoverOpenRisk = useCallback((event) => {
    setOpenRiskMenu(!openRiskMenu);
    setOpenMenu(false);
    setAnchorElRisk(event.currentTarget);
  });

  const handlePopoverClose = useCallback(() => {
    menuClick(0);
    setAnchorEl(null);
  });
  const handlePopoverCloseRisk = useCallback(() => {
    menuClick(0);
    setAnchorElRisk(null);
  });

  const handleChangeSwitch = useCallback((event) => {
    setSwitchShow(!switchShow);
  });

  const openPop = Boolean(anchorEl);
  const openPopRisk = Boolean(anchorElRisk);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Toolbar className="toolbar-height">
        <div className={classes.drawerHeader}>
          {open === false && (
            <IconButton
              onClick={handleDrawerOpen}
              className="left-yello-menu"
              style={{ backgroundColor: color ? color : "#262626 !important" }}
            >
              {theme.direction === "ltr" ? (
                <MenuIcon className="left-menu-icon-yellow " />
              ) : null}
            </IconButton>
          )}
        </div>
      </Toolbar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Box className="img-holder1">
            <img className="img-fluid" src={img1} alt="" />
          </Box>
          <div className="box-shadow-menu-button">
            <IconButton onClick={handleDrawerClose} className="left-white-menu">
              {theme.direction === "ltr" ? <MenuIcon /> : null}
            </IconButton>
          </div>
        </div>

        <List>
          <ListItem
            button
            className={"list-item-style-Inactive"}
            style={{ marginBottom: "21px !important" }}
            onClick={() => menuClick(0)}
          >
            <ListItemIcon>
              <DashboardOutlinedIcon className="p-r-10" id="collapsible" />

              <img
                src={arrow}
                alt=""
                className={
                  menuIndex === 0 ? "icon-style-active" : "icon-style-Inactive"
                }
              />
            </ListItemIcon>
            <ListItemText primary="Home" className="p-l-20" />
          </ListItem>

          {switchShow ? (
            <ListItem
              button
              className={"list-item-style-Inactive"}
              onClick={() => menuClick(10)}
            >
              <ListItemIcon>
              <img src={Analytics} alt="" style={{color:"black",width:"20px",marginRight:"-3px"}} />

                <img
                  src={arrow}
                  alt=""
                  className={
                    menuIndex === 10
                      ? "icon-style-active"
                      : "icon-style-Inactive"
                  }
                />
              </ListItemIcon>

              <ListItemText primary="Analytics" className="p-l-20 mr-7" />
            </ListItem>
          ) : (
            <Link
              to={"/analytics-dashboard"}
              target="_blank"
              style={{ color: "#000000", textDecoration: "none" }}
            >
              <ListItem
                button
                className={"list-item-style-Inactive"}
                onClick={() => menuClick(10)}
              >
                <ListItemIcon>
                <img src={Analytics} alt="" style={{color:"#000000",marginRight:"-3px"}} />

                  <img src={arrow} alt="" className={"icon-style-Inactive"} />
                </ListItemIcon>

                <ListItemText primary="Analytics" className="p-l-20 mr-7" />
              </ListItem>
            </Link>
          )}

          <ListItem
            button
            className={"list-item-style-Inactive"}
            aria-owns={openPopRisk ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={handlePopoverOpenRisk}
          >
            <ListItemIcon>
              <WarningAmberIcon className="p-r-10" id="collapsible" />

              <img
                src={arrow}
                alt=""
                className={
                  menuIndex === 2 || menuIndex === 3 || menuIndex === 4
                    ? "icon-style-active"
                    : "icon-style-Inactive"
                }
              />
            </ListItemIcon>

            <ListItemText primary="Risk" className="p-l-20" />
          </ListItem>
          <ul
            style={{
              display: riskShow ? "block" : "none",
              backgroundColor: "#f3be50",
            }}
          >
            <li style={{ paddingBottom: "7px" }}>
              <Link
                className="menuitem"
                to={"/risk"}
                target="_blank"
                style={{ color: "rgba(0, 0, 0, 0.54)" }}
              >
                Risk
              </Link>
            </li>
            {/* <li className="w-200" style={{ paddingBottom: "7px" }}>
              <Link
                to={"/digital"}
                className="menuitem"
                target="_blank"
                style={{ color: "rgba(0, 0, 0, 0.54)" }}
              >
                Risk Mobile
              </Link>
            </li> */}
            <li className="w-200" style={{ paddingBottom: "7px" }}>
              <Link
                to={"/risk-analytics"}
                className="menuitem"
                target="_blank"
                style={{ color: "rgba(0, 0, 0, 0.54)" }}
              >
                Risk Analytics
              </Link>
            </li>
          </ul>

          <ListItem
            button
            className={"list-item-style-Inactive"}
            aria-owns={openPop ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={handlePopoverOpen}
          >
            <ListItemIcon>
              <TextSnippetIcon
                className="p-r-10"
                id="collapsible"
                onClick={showClaims}
              />

              <img
                src={arrow}
                alt=""
                className={
                  menuIndex === 1 || menuIndex === 6 || menuIndex === 7
                    ? "icon-style-active"
                    : "icon-style-Inactive"
                }
              />
            </ListItemIcon>

            <ListItemText primary="Claims" className="p-l-20" />
          </ListItem>

          {switchShow ? (
            <ListItem
              button
              className={"list-item-style-Inactive"}
              onClick={() => menuClick(8)}
            >
              <ListItemIcon>
                <LanguageIcon className="p-r-10" id="collapsible" />

                <img
                  src={arrow}
                  alt=""
                  className={
                    menuIndex === 8
                      ? "icon-style-active"
                      : "icon-style-Inactive"
                  }
                />
              </ListItemIcon>

              <ListItemText primary="Digital" className="p-l-20" />
            </ListItem>
          ) : (
            <Link
              to={"/digital"}
              target="_blank"
              style={{ color: "#000000", textDecoration: "none" }}
            >
              <ListItem
                button
                className={"list-item-style-Inactive"}
                onClick={() => menuClick(0)}
              >
                <ListItemIcon>
                  <LanguageIcon className="p-r-10" id="collapsible" />

                  <img src={arrow} alt="" className={"icon-style-Inactive"} />
                </ListItemIcon>

                <ListItemText primary="Digital" className="p-l-20" />
              </ListItem>
            </Link>
          )}
{switchShow ? (
            <ListItem
              button
              className={"list-item-style-Inactive-billing"}
              // onClick={() => menuClick(11)}
            >
              <ListItemIcon>
              <img src={BillingImg} alt="" style={{color:"#a0a0a08a",width:"20px",opacity:'0.5'}} />

                <img
                  src={arrow}
                  alt=""
                  className={
                    menuIndex === 11
                      ? "icon-style-active"
                      : "icon-style-Inactive"
                  }
                />
              </ListItemIcon>

              <ListItemText primary="Policy" className="p-l-20 grey-text" />
            </ListItem>
          ) : (
            // <Link
            //   to={"/billing"}
            //   target="_blank"
            //   style={{ color: "#000000", textDecoration: "none" }}
            // >
              <ListItem
                button
                className={"list-item-style-Inactive-billing"}
                onClick={() => menuClick(0)}
              >
                <ListItemIcon>
                  {/* <LanguageIcon className="p-r-10" id="collapsible" /> */}
                   <img src={BillingImg} alt="" style={{color:"#a0a0a08a",width:"20px",opacity:'0.5'}} />
                  <img src={arrow} alt="" className={"icon-style-Inactive"} />
                </ListItemIcon>

                <ListItemText primary="Policy" className="p-l-20 grey-text" />
              </ListItem>
            // </Link>
          )}


          {switchShow ? (
            <ListItem
              button
              className={"list-item-style-Inactive-billing"}
              // onClick={() => menuClick(11)}
            >
              <ListItemIcon>
              <img src={BillingImg} alt="" style={{color:"#a0a0a08a",width:"20px",opacity:'0.5'}} />

                <img
                  src={arrow}
                  alt=""
                  className={
                    menuIndex === 11
                      ? "icon-style-active"
                      : "icon-style-Inactive"
                  }
                />
              </ListItemIcon>

              <ListItemText primary="Billing" className="p-l-20 grey-text" />
            </ListItem>
          ) : (
            // <Link
            //   to={"/billing"}
            //   target="_blank"
            //   style={{ color: "#000000", textDecoration: "none" }}
            // >
              <ListItem
                button
                className={"list-item-style-Inactive-billing"}
                onClick={() => menuClick(0)}
              >
                <ListItemIcon>
                  {/* <LanguageIcon className="p-r-10" id="collapsible" /> */}
                   <img src={BillingImg} alt="" style={{color:"#a0a0a08a",width:"20px",opacity:'0.5'}} />
                  <img src={arrow} alt="" className={"icon-style-Inactive"} />
                </ListItemIcon>

                <ListItemText primary="Billing" className="p-l-20 grey-text" />
              </ListItem>
            // </Link>
          )}
        </List>
      </Drawer>
      {switchShow && openRiskMenu ? (
        <div className={openRiskMenu ? "show-menu" : "hide-menu"}>
          <li onClick={() => menuClick(4)} className="menu-list">
            Risk
          </li>
          <br />{" "}
          {/* <li onClick={() => menuClick(3)} className="menu-list">
            Risk Mobile
          </li> */}
          <br />{" "}
          <li onClick={() => menuClick(2)} className="menu-list">
            Risk Analytics
          </li>
        </div>
      ) : switchShow === false && openRiskMenu ? (
        <div className={openRiskMenu ? "show-menu" : "hide-menu"}>
          <Link
            to={"/risk"}
            target="_blank"
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              textDecoration: "none",
            }}
          >
            <li className="menu-list" onClick={() => menuClick(0)}>
              Risk
            </li>
          </Link>
          <br />{" "}
          <Link
            to={"/digital"}
            target="_blank"
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              textDecoration: "none",
            }}
          >
            {/* <li className="menu-list" onClick={() => menuClick(0)}>
              Risk Mobile
            </li> */}
          </Link>
          <br />{" "}
          <Link
            to={"/risk-analytics"}
            target="_blank"
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              textDecoration: "none",
            }}
          >
            <li className="menu-list" onClick={() => menuClick(0)}>
              Risk Analytics
            </li>
          </Link>
        </div>
      ) : switchShow && openMenu ? (
        <div className={openMenu ? "show-menu-claim" : "hide-menu"}>
          <li onClick={() => menuClick(6)} className="menu-list">
            Claims
          </li>
          <br />{" "}
          {/* <li onClick={() => menuClick(7)} className="menu-list">
            Claims Mobile
          </li> */}
          <br />{" "}
          <li onClick={() => menuClick(1)} className="menu-list">
            Claims Analytics
          </li>
        </div>
      ) : switchShow === false && openMenu ? (
        <div className={openMenu ? "show-menu-claim" : "hide-menu"}>
          <Link
            to={"/claims"}
            target="_blank"
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              textDecoration: "none",
            }}
          >
            <li className="menu-list" onClick={() => menuClick(0)}>
              {" "}
              Claims
            </li>
          </Link>
          <br />{" "}
          {/* <Link
            to={"/digital"}
            target="_blank"
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              textDecoration: "none",
            }}
          >
            <li className="menu-list" onClick={() => menuClick(0)}>
              Claims Mobile
            </li>
          </Link> */}
          <br />{" "}
          <Link
            to={"/claims-analytics"}
            target="_blank"
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              textDecoration: "none",
            }}
          >
            <li className="menu-list" onClick={() => menuClick(0)}>
              Claims Analytics
            </li>
          </Link>
        </div>
      ) : null}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <>
          <Toolbar className="toolbar-height">
            <div className={classes.drawerHeader}>
              {rightOpen === false && menuIndex === 0 && (
                <IconButton
                  onClick={handleRightDrawerOpen}
                  className="right-drawer-open"
                  style={{
                    backgroundColor: color ? color : "#262626 !important",
                  }}
                >
                  {theme.direction === "ltr" ? <MenuIcon /> : null}
                </IconButton>
              )}
            </div>
          </Toolbar>
          <Drawer
            className={classes.drawerRight}
            variant="persistent"
            anchor="right"
            open={rightOpen}
            classes={{
              paper: classes.drawerPaperRight,
            }}
          >
            <div className={classes.drawerHeader}>
              {propsIndex === "true" || menuIndex === 0 ? (
                <div className="box-shadow-menu-button-right">
                  <IconButton
                    onClick={handleRightDrawerClose}
                    className="right-menu-icon-close"
                    style={{
                      backgroundColor: color ? color : "#fcb426 !important",
                    }}
                  >
                    {theme.direction === "ltr" ? <MenuIcon /> : null}
                  </IconButton>
                </div>
              ) : null}
            </div>
            {propsIndex === "true" || menuIndex === 0 ? (
              <Grid
                className="side-container-holder ff-m"
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
              >
                <Box className="w-100 d-flex space-around gap-75 mb-10 mt-25">
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: "11px",
                      lineHeight: "19px",
                      marginLeft: "16px",
                    }}
                  >
                    Also See
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: "11px",
                      lineHeight: "16px",
                      color: "#3E4044",
                      marginRight: "16px",
                      cursor: "pointer",
                    }}
                  >
                    Explore
                  </Box>
                </Box>
                <NewesSection />
              </Grid>
            ) : null}
          </Drawer>

          {menuIndex === 0 && (
            <FormControlLabel
              className="switch-m-l"
              control={
                <Switch
                  size="small"
                  checked={switchShow}
                  onChange={handleChangeSwitch}
                  color="primary"
                />
              }
              label={switchShow ? "i-Frame" : "In new tab"}
            />
          )}

          <main
            onClick={closeMenu}
            className={
              menuIndex === 0
                ? clsx(classes.contentRight, {
                    [classes.contentShiftRight]: rightOpen,
                  })
                : clsx(classes.contentRightMenu, {
                    [classes.contentShiftRight]: rightOpen,
                  })
            }
          >
            {" "}
            {rightOpen ? (
              <Box
                className={
                  "d-flex space-between sm-space-around mr-20 header-home w-72"
                }
              >
                <Box className="tle-20-bld mt-10">
                  {menuIndex === 0 && "Home"}
                  {menuIndex === 1 && "Claims Analytics"}
                  {menuIndex === 2 && "Risk Analytics"}
                  {menuIndex === 3 && "Digital"}
                  {menuIndex === 4 && "Risk"}
                  {menuIndex === 7 && "Digital"}
                  {menuIndex === 8 && "Digital"}
                </Box>
                {menuIndex === 9 ? null : (
                  <Box className="d-flex gap-30">
                    <Box className="tle-12-bld mt-6">🇬🇧 English</Box>
                    <Box className="tle-12-bld bg-bck">
                      <Box className="tle-12-bld">RP</Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                className={
                  "d-flex space-between sm-space-around mr-20 header-home w-82"
                }
              >
                <Box className="tle-20-bld mt-10">
                  {menuIndex === 0 && "Home"}
                  {menuIndex === 1 && "Claims Analytics"}
                  {menuIndex === 2 && "Risk Analytics"}
                  {menuIndex === 3 && "Digital"}
                  {menuIndex === 4 && "Risk"}
                  {menuIndex === 7 && "Digital"}
                  {menuIndex === 8 && "Digital"}
                </Box>
                {menuIndex === 9 ? null : (
                  <Box className="d-flex gap-30">
                    <Box className="tle-12-bld mt-6">🇬🇧 English</Box>
                    <Box className="tle-12-bld bg-bck">
                      <Box className="tle-12-bld">RP</Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {menuIndex === 0 && (
              <Dashboard
                update={onClickFunction}
                rightOpen={rightOpen}
                leftOpen={open}
                switchShow ={switchShow}
              />
            )}
            {menuIndex === 1 && <ClaimsAnalytics update={onClickFunction} />}
            {menuIndex === 2 && <RiskAnalytics update={onClickFunction} />}
            {menuIndex === 3 && <DigitalFrame update={onClickFunction} />}
            {menuIndex === 7 && <DigitalFrame update={onClickFunction} />}
            {menuIndex === 8 && <DigitalFrame update={onClickFunction} />}
            {menuIndex === 4 && <RiskFrame update={onClickFunction} />}
            {menuIndex === 5 && <Dashboard update={onClickFunction} />}
            {menuIndex === 6 && <ClaimsFrame update={onClickFunction} />}
            {menuIndex === 10 && <AnalyticsFrame update={onClickFunction} />}
            {menuIndex === 12 && <DigitalIntake update={onClickFunction} />}
          </main>
        </>

        <div className={classes.drawerHeader} />
      </main>
    </div>
  );
}
