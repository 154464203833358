import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import bgSlide from "../../asset/icon/photo-1661956601031-4cf09efadfce.avif";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import "./styles.css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import { Box } from "@mui/material";

export default function SideSlider4() {
  return (
    <>
      {/* <Swiper
        navigation={true}
        pagination={true}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Box className="slide-img5">
            <Box className="slider-tilte px-4">Touchless Claims</Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Box className="slide-img2">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img3">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img4">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper> */}
    </>
  );
}
