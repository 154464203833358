import Drawer from "./components/Drawer/Drawer";
import Language from "./pages/language/Language";
import Login from "./pages/login/Login";
import Password from "./pages/password/Password";
import Authentication from "./pages/authentication/Authentication";

import Risk from "./pages/Risk/Risk";
import Claims from "./pages/Claims/Claims";
import ClaimsAnalytics from "./pages/ClaimsAnalyticsTab/ClaimsAnalyticsTab";
import Digital from "./pages/Digital/Digital";
import Analytics from "./pages/Analytics/Analytics";
import RiskAnalytics from "./pages/RiskAnalyticsTab/RiskAnalyticsTab";
import Admin from "./components/Admin/Admin";
import Policy from "./pages/Policy/Analytics";
import DigitalIntake from "./pages/Digital-Intake/DigitalIntake";


import { BrowserRouter, Route, Routes } from "react-router-dom";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Language />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/passw" element={<Password />} />
          <Route path="/auth" element={<Authentication />} />
          <Route path="/navtabs" element={<Drawer indexMenu={"true"} />} />
          {/* <Route path="/" element={<Navtabs />} /> */}
          <Route path="/risk" element={<Risk />} />
          <Route path="/claims" element={<Claims />} />
          <Route path="/claims-analytics" element={<ClaimsAnalytics />} />
          <Route path="/risk-analytics" element={<RiskAnalytics />} />
          <Route path="/digital" element={<Digital />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/analytics-dashboard" element={<Policy />} />
          <Route path="/billing" element={<Policy />} />
          <Route path="/digital-intake" element={<DigitalIntake />} />



        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
