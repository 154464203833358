import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import RiskImg from "../../asset/new screens/RiskAQDashboard.png";

export default function Risk() {
  return (
    <Grid container>
      <Grid
        className="mb-30 card-grid1 ff-m"
        sx={{ position: "relative" }}
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Box className="titleRisk">
          <img src={RiskImg} class="img-fluid-menu" alt="..."></img>
          {/* <iframe
            height="600px"
            src={RiskImg}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{
              marginLeft: "-15px",
              marginTop: "-15px",
              width: "-webkit-fill-available",
            }}
          /> */}
        </Box>
      </Grid>
    </Grid>
  );
}
