import React from 'react'
import { Bar, XAxis, BarChart, ResponsiveContainer } from 'recharts'

export default function BarChart1() {
    const BarData = [
        {
            number:"-15%",
            time:5   
        },
        {
            number:"-10%",
            time:10    
        },
        {
            number:"-5%",
            time:15   
        },
        {
            number:"0%",
            time:20  
        },
        {
            number:"1%",
            time:15    
        },
        {
            number:"5%",
            time:10    
        },
        {
            number:"10%",
            time:5    
        },
        {
            number:"15%",
            time:3    
        },
    ]
  return (
    <div>
        <ResponsiveContainer width="100%" interval="preserveStartEnd" aspect={2}>
            <BarChart  data={BarData}>
                <XAxis dataKey="number" />
                <Bar dataKey="time" fill="#CDE7ED" />
            </BarChart>
        </ResponsiveContainer>
    </div>
  )
}
