import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import bgSlide from "../../asset/icon/photo-1661956601031-4cf09efadfce.avif";
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { Icon } from '@material-ui/core';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import "./styles.css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import { Box } from "@mui/material";

export default function SideSlider2() {
  return (
    <>
      {/* <Swiper
        navigation={true}
        pagination={true}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Box className="slide-img3">
            <Box className="slider-tilte px-4">
              Sorting Through the RMIS Vendor Noise
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Box className="slide-img2">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img1">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img4">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper> */}
       <Box className="slide-img3">
            <Box></Box>
          </Box>

      <Box className="box-cont-holder">
        <Box className="side-content-box">
          
          <Box className="sb-til">
         
         April 24, 2023 | April 26, 2023
            
          </Box>
          <Box className="sb-til">
          <Icon ><RoomOutlinedIcon/></Icon>
          Hilton Grand Place Brussels, Belgium
          </Box>
          <Box className="tle-12-bld-7">Ventive Experience 2023 Brussels</Box>
        </Box>
      </Box>
    </>
  );
}
